import React from 'react'
import Prismic from 'prismic-javascript';
import PostCard from "./postCard"
import { ThemeContext } from "../theme-context"

const apiEndpoint = "https://mteixeiradev.prismic.io/api/v2";

export default class Thoughts extends React.Component {
  state = {
    posts: [],
    loading: true
  }

  componentWillMount() {
    Prismic.api(apiEndpoint).then(api => {
      api.query(Prismic.Predicates.at('document.type', 'post')).then(response => {
        console.log({ response });
        if (response) {
          this.setState({ posts: response.results });
        }
      });
    });
  }

  render() {
    const { posts = [] } = this.state;
    return (
    <ThemeContext.Consumer>
        {({ state: { style } }) => (
          posts.length > 0 ?
            <>
              <h2 className={style === 'dark' ? "text-white" : ""}>My Thoughts</h2>
              <p className={`f4 mb-4 ${style === 'dark' ? 'text-white' : 'text-gray'}`}>Articles I've written.</p>
              <div className="d-sm-flex flex-wrap gutter-condensed mb-4">
                {posts.map((post, index) => (
                  <div key={index} className="col-sm-6 col-md-12 col-lg-6 col-xl-4 mb-3">
                    <PostCard post={post} />
                  </div>
                ))}
              </div>
            </>
            : null
        )}
    </ThemeContext.Consumer>
    )
  }
}