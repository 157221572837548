import React, { useContext } from 'react'
import Emoji from 'react-emoji-render'
import { ThemeContext } from "../theme-context"

function RepoCard({ repository, viewer }) {
  const { state: { style } } = useContext(ThemeContext)
  return (
    <div className={`github-component height-full text-left ${style === 'dark' ? 'box-shadow' : 'border border-gray-light'} bg-white rounded-1 p-3`}>
      <div className="d-flex flex-justify-between flex-items-start mb-1">
        <h1 className="f4 lh-condensed mb-1">
          <a href={(repository.homepageUrl || repository.url).replace(/utm_source=[^&]*/, 'utm_source=mtxr-website')} target='_blank'>
            {!viewer || viewer.login !== repository.owner.login && <span className="text-normal">{repository.owner.login}/</span>}
            {repository.name}
          </a>
        </h1>
      </div>
      <div className="text-gray mb-2 ws-normal"><Emoji text={repository.description || ''} /></div>
      <div className="d-flex f6">
        {repository.primaryLanguage && (
          <a href={repository.url} className="d-inline-block link-gray mr-4">
            <span className="language-color" style={{ background: repository.primaryLanguage.color }}/>{repository.primaryLanguage.name}
          </a>
        )}
        <a href={repository.url} className="d-inline-block link-gray mr-4">
          <svg className="octicon octicon-star mr-1" viewBox="0 0 24 24" width="16" height="16"><path fill-rule="evenodd" d="M12 .25a.75.75 0 01.673.418l3.058 6.197 6.839.994a.75.75 0 01.415 1.279l-4.948 4.823 1.168 6.811a.75.75 0 01-1.088.791L12 18.347l-6.117 3.216a.75.75 0 01-1.088-.79l1.168-6.812-4.948-4.823a.75.75 0 01.416-1.28l6.838-.993L11.328.668A.75.75 0 0112 .25zm0 2.445L9.44 7.882a.75.75 0 01-.565.41l-5.725.832 4.143 4.038a.75.75 0 01.215.664l-.978 5.702 5.121-2.692a.75.75 0 01.698 0l5.12 2.692-.977-5.702a.75.75 0 01.215-.664l4.143-4.038-5.725-.831a.75.75 0 01-.565-.41L12 2.694z"></path></svg>{repository.stargazers.totalCount}
        </a>
        <a href={repository.url} className="d-inline-block link-gray mr-4">
          <svg className="octicon octicon-git-fork mr-1" viewBox="0 0 24 24" width="16" height="16"><path fill-rule="evenodd" d="M12 21a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5zm-3.25-1.75a3.25 3.25 0 106.5 0 3.25 3.25 0 00-6.5 0zm-3-12.75a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5zM2.5 4.75a3.25 3.25 0 106.5 0 3.25 3.25 0 00-6.5 0zM18.25 6.5a1.75 1.75 0 110-3.5 1.75 1.75 0 010 3.5zM15 4.75a3.25 3.25 0 106.5 0 3.25 3.25 0 00-6.5 0z"></path><path fill-rule="evenodd" d="M6.5 7.75v1A2.25 2.25 0 008.75 11h6.5a2.25 2.25 0 002.25-2.25v-1H19v1a3.75 3.75 0 01-3.75 3.75h-6.5A3.75 3.75 0 015 8.75v-1h1.5z"></path><path fill-rule="evenodd" d="M11.25 16.25v-5h1.5v5h-1.5z"></path></svg>{repository.forkCount}
        </a>
        {/* <a href={repository.url} className="d-inline-block link-gray mr-4">
          <svg className="octicon octicon-people mr-1" viewBox="0 0 24 24" width="16" height="16"><path fill-rule="evenodd" d="M3.5 8a5.5 5.5 0 118.596 4.547 9.005 9.005 0 015.9 8.18.75.75 0 01-1.5.045 7.5 7.5 0 00-14.993 0 .75.75 0 01-1.499-.044 9.005 9.005 0 015.9-8.181A5.494 5.494 0 013.5 8zM9 4a4 4 0 100 8 4 4 0 000-8z"></path><path d="M17.29 8c-.148 0-.292.01-.434.03a.75.75 0 11-.212-1.484 4.53 4.53 0 013.38 8.097 6.69 6.69 0 013.956 6.107.75.75 0 01-1.5 0 5.193 5.193 0 00-3.696-4.972l-.534-.16v-1.676l.41-.209A3.03 3.03 0 0017.29 8z"></path></svg>{repository.forkCount}
        </a> */}
      </div>
    </div>
  )
}

export default RepoCard
