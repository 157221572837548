import React from "react"
import RepoCard from "./repoCard"
import { ThemeContext } from "../theme-context"
import { gql } from "apollo-boost"
import { Query } from "react-apollo"

const PROJECT_QUERY = gql`
{
  viewer {
    name
    email
    bio
    login
    url
    avatarUrl
    location
    repositories(affiliations: OWNER, privacy: PUBLIC) {
      totalCount
    }
    contributionsCollection {
      contributionCalendar {
        totalContributions
      }
    }
    pinnedItems(first: 9) {
      totalCount
      nodes {
        ... on Repository {
          name
          url
          description
          forkCount
          homepageUrl
          updatedAt
          stargazers {
            totalCount
          }
          owner {
            login
          }
          primaryLanguage {
            id
            name
            color
          }
        }
      }
    }
  }
}
`

export default class Projects extends React.Component {
  state = {
    loading: true,
    failed: false,
    viewer: {},
    pinnedItems: { nodes: [] },
    repositories: { totalCount: 0 },
  }

  renderRepos = (style, { viewer, repositories, pinnedItems }) => {
    return (
      <>
        {pinnedItems.nodes.map((repository, i) => (
          <div key={i} className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 github-component-container">
            <RepoCard repository={repository} viewer={viewer}/>
            {i === 0 && (
              <svg className='repo-highlight' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill-rule="evenodd" fill='#FFD700' d="M12.672.668a.75.75 0 00-1.345 0L8.27 6.865l-6.838.994a.75.75 0 00-.416 1.279l4.948 4.823-1.168 6.811a.75.75 0 001.088.791L12 18.347l6.117 3.216a.75.75 0 001.088-.79l-1.168-6.812 4.948-4.823a.75.75 0 00-.416-1.28l-6.838-.993L12.672.668z"></path></svg>
            )}
          </div>
        ))}
        <div className="col-md-12">
          <p className='text-center'>
            {viewer.contributionsCollection.contributionCalendar.totalContributions} contributions in the last year
          </p>
        </div>
        <div className="col-md-12">
          <p
            className={`text-center f4 mb-4 ${style === "dark" ? "text-white" : "text-gray"}`}
          >
            <a href={`${viewer.url}?tab=repositories`} target="_blank">
              See all {repositories.totalCount}{" "}
              repositories
            </a>
          </p>
        </div>
      </>
    )
  }
  render() {
    return (
      <ThemeContext.Consumer>
        {({ state: { style } }) => (
          <>
            <h2 className={style === "dark" ? "text-white" : ""}>
              Open Source Projects
            </h2>
            <p
              className={`f4 mb-4 ${
                style === "dark" ? "text-white" : "text-gray"
              }`}
            >
              GitHub repositories that I've built or contributed.
            </p>
            <div className="d-sm-flex flex-wrap gutter-condensed mb-4">
              <Query query={PROJECT_QUERY}>
                {({
                  data,
                  loading,
                  error,
                }) => {
                  if (error)
                    return (
                      <div className="text-center">
                        Failed to fetch repositories
                      </div>
                    );
                  if (loading || !data || !data.viewer) {
                    return <div className="text-center col-md-12">...</div>
                  }
                  const { repositories, pinnedItems, ...viewer } = data.viewer;

                  return this.renderRepos(style, {
                    repositories,
                    pinnedItems,
                    viewer,
                  })
                }}
              </Query>
            </div>
          </>
        )}
      </ThemeContext.Consumer>
    )
  }
}
